/* eslint-disable max-len */
import peru from 'assets/images/caseStudies/Covers/peru1.png';
import globalWaterProgram from 'assets/images/caseStudies/Covers/globalWaterProgram2.jpg';
import tunisia from 'assets/images/caseStudies/Covers/tunisia3.jpg';
import lakeChad from 'assets/images/caseStudies/Covers/lakeChad4.jpg';
import nileBassin from 'assets/images/caseStudies/Covers/nileBassin5.jpg';
import zambia from 'assets/images/caseStudies/Covers/zambia6.jpg';
import jordan7 from 'assets/images/caseStudies/Covers/jordan7.jpg';
import jordan8 from 'assets/images/caseStudies/Covers/jordan8.jpg';
import ethiopia from 'assets/images/caseStudies/Covers/ethiopia9.jpg';
import yemen from 'assets/images/caseStudies/Covers/yemen10.jpg';
import mongolia from 'assets/images/caseStudies/Covers/mongolia11.jpg';

import au from 'assets/images/caseStudies/Flags/AU.svg';
import bi from 'assets/images/caseStudies/Flags/BI.svg';
import cd from 'assets/images/caseStudies/Flags/CD.svg';
import cf from 'assets/images/caseStudies/Flags/CF.svg';
import cm from 'assets/images/caseStudies/Flags/CM.svg';
import eg from 'assets/images/caseStudies/Flags/EG.svg';
import et from 'assets/images/caseStudies/Flags/ET.svg';
import jo from 'assets/images/caseStudies/Flags/JO.svg';
import ke from 'assets/images/caseStudies/Flags/KE.svg';
import mn from 'assets/images/caseStudies/Flags/MN.svg';
import ne from 'assets/images/caseStudies/Flags/NE.svg';
import ng from 'assets/images/caseStudies/Flags/NG.svg';
import pe from 'assets/images/caseStudies/Flags/PE.svg';
import rw from 'assets/images/caseStudies/Flags/RW.svg';
import sd from 'assets/images/caseStudies/Flags/SD.svg';
import ss from 'assets/images/caseStudies/Flags/SS.svg';
import td from 'assets/images/caseStudies/Flags/TD.svg';
import tn from 'assets/images/caseStudies/Flags/TN.svg';
import tz from 'assets/images/caseStudies/Flags/TZ.svg';
import ug from 'assets/images/caseStudies/Flags/UG.svg';
import ye from 'assets/images/caseStudies/Flags/YE.svg';
import zm from 'assets/images/caseStudies/Flags/ZM.svg';

import map2 from 'assets/images/caseStudies/Maps/african.png';
import map9 from 'assets/images/caseStudies/Maps/ethiopia.png';
import map7 from 'assets/images/caseStudies/Maps/jordan.png';
import map8 from 'assets/images/caseStudies/Maps/jordan2.png';
import map4 from 'assets/images/caseStudies/Maps/lakechad.png';
import map11 from 'assets/images/caseStudies/Maps/mongolia.png';
import map5 from 'assets/images/caseStudies/Maps/nilebasin.png';
import map1 from 'assets/images/caseStudies/Maps/peru.png';
import map3 from 'assets/images/caseStudies/Maps/tunisia.png';
import map10 from 'assets/images/caseStudies/Maps/yemen.png';
import map6 from 'assets/images/caseStudies/Maps/zambia.png';

import type8 from 'assets/images/caseStudies/Types/climateresiliance.svg';
import type6 from 'assets/images/caseStudies/Types/integratedwaterresourcemanagementtype.svg';
import type2 from 'assets/images/caseStudies/Types/protection.svg';
import type1 from 'assets/images/caseStudies/Types/transboundarywatermanagementtype.svg';
import type7 from 'assets/images/caseStudies/Types/waterpeace.svg';
import type9 from 'assets/images/caseStudies/Types/wefenexus.svg';
import { theme } from 'twin.macro';

const useCasesLegendType1 = [
  {
    color: theme`colors.lighBlue.50`,
    value: 'NA',
  },
  {
    color: theme`colors.yellow.50`,
    value: '0% - 5%',
  },
  {
    color: theme`colors.yellow.100`,
    value: '5% - 10%',
  },
  {
    color: theme`colors.yellow.200`,
    value: '10% - 15%',
  },
  {
    color: theme`colors.yellow.400`,
    value: '15% - 20%',
  },
  {
    color: theme`colors.yellow.600`,
    value: '20% - 30%',
  },
  {
    color: theme`colors.yellow.700`,
    value: '30% - 50%',
  },
  {
    color: theme`colors.yellow.800`,
    value: '50% - 100%',
  },
];

const useCasesLegendType2 = [
  {
    color: theme`colors.orange.600`,
    value: 'More than -500 ha',
  },
  {
    color: theme`colors.orange.400`,
    value: '-500ha to -50 ha',
  },
  {
    color: theme`colors.orange.200`,
    value: '-50ha to -1 ha',
  },
  {
    color: theme`colors.blue.100`,
    value: '-1ha to 1 ha',
  },
  {
    color: theme`colors.blue.300`,
    value: '1ha to 50 ha',
  },
  {
    color: theme`colors.blue.500`,
    value: '50ha to 500 ha',
  },
  {
    color: theme`colors.blue.700`,
    value: 'More than 500ha',
  },
];

const useCasesLegendType3 = [
  {
    color: theme`colors.blue.100`,
    value: '>1.700 m3',
  },
  {
    color: theme`colors.orange.200`,
    value: '1.000 - 1.700 -1 m3',
  },
  {
    color: theme`colors.orange.400`,
    value: '500 - 1.000 m3',
  },
  {
    color: theme`colors.orange.600`,
    value: '<500 m3',
  },
];

export const useCasesLegend: any = [
  {
    title: 'change in hectares per pixel',
    id: 1,
    legend: useCasesLegendType2,
  },
  {
    title: 'change in hectars per pixel',
    id: 2,
    legend: useCasesLegendType3,
  },
  {
    title: 'average water availability per person in pixels',
    id: 3,
    legend: useCasesLegendType3,
  },
  {
    title: 'change in hectars per pixel',
    id: 4,
    legend: useCasesLegendType2,
  },
  {
    title: 'change in hectars per pixel',
    id: 5,
    legend: useCasesLegendType2,
  },
  {
    title: 'change in hectars per pixel',
    id: 6,
    legend: useCasesLegendType2,
  },
  {
    title: 'change in hectars per pixel',
    id: 7,
    legend: useCasesLegendType2,
  },
  {
    title: 'average water availability per person in pixels',
    id: 8,
    legend: useCasesLegendType3,
  },

  {
    title: 'change in hectars per pixel',
    id: 9,
    legend: useCasesLegendType2,
  },
  {
    title: 'average water availability per person in pixels',
    id: 10,
    legend: useCasesLegendType3,
  },
  {
    title: 'share of threatened freshwater species',
    id: 11,
    legend: useCasesLegendType1,
  },
];

export const useCasesList = [
  // Peru
  {
    id: 0,
    cover: peru,
    types: [
      { img: type2, text: 'ecosystem_restoration_protection' },
      { img: type9, text: 'WEFE_nexus' },
    ],
    flags: [
      {
        id: 1,
        country: 'PE',
        image: pe,
      },
    ],
    map: map1,
    url: 'https://www.giz.de/en/worldwide/132883.html',
  },
  // Africa global
  {
    id: 1,
    cover: globalWaterProgram,
    types: [
      { img: type1, text: 'TWM' },
      { img: type7, text: 'water_peace' },
    ],
    flags: [
      {
        id: 1,
        country: 'AU',
        image: au,
      },
    ],
    map: map2,
    url: '',
  },
  // Tunisia
  {
    id: 2,
    cover: tunisia,
    types: [
      { img: type2, text: 'ecosystem_restoration_protection' },
      { img: type6, text: 'IWRM' },
      { img: type8, text: 'climate_resiliance' },
    ],
    flags: [
      {
        id: 1,
        country: 'TN',
        image: tn,
      },
    ],
    map: map3,
    url: 'https://www.giz.de/en/worldwide/139253.html',
  },
  // Lake Chad
  {
    id: 3,
    cover: lakeChad,
    types: [{ img: type1, text: 'TWM' }],
    flags: [
      {
        id: 1,
        country: 'CM',
        image: cm,
      },
      {
        id: 2,
        country: 'CF',
        image: cf,
      },
      {
        id: 3,
        country: 'TD',
        image: td,
      },
      {
        id: 4,
        country: 'NE',
        image: ne,
      },
      {
        id: 5,
        country: 'NG',
        image: ng,
      },
    ],
    map: map4,
    url: 'https://www.giz.de/en/worldwide/136508.html',
  },
  // Nile
  {
    id: 4,
    cover: nileBassin,
    types: [
      { img: type1, text: 'TWM' },
      { img: type7, text: 'water_peace' },
    ],
    flags: [
      {
        id: 1,
        country: 'EG',
        image: eg,
      },
      {
        id: 1,
        country: 'ET',
        image: et,
      },
      {
        id: 1,
        country: 'BI',
        image: bi,
      },
      {
        id: 1,
        country: 'CD',
        image: cd,
      },
      {
        id: 1,
        country: 'KE',
        image: ke,
      },
      {
        id: 1,
        country: 'RW',
        image: rw,
      },
      {
        id: 1,
        country: 'SD',
        image: sd,
      },
      {
        id: 1,
        country: 'SS',
        image: ss,
      },
      {
        id: 1,
        country: 'TZ',
        image: tz,
      },
      {
        id: 1,
        country: 'UG',
        image: ug,
      },
    ],
    map: map5,
    url: 'https://www.giz.de/en/worldwide/138720.html',
  },
  // Zambia
  {
    id: 5,
    cover: zambia,
    types: [
      { img: type6, text: 'IWRM' },
      { img: type2, text: 'ecosystem_restoration_protection' },
    ],
    flags: [
      {
        id: 1,
        country: 'ZM',
        image: zm,
      },
    ],
    map: map6,
    url: 'https://www.giz.de/en/worldwide/138796.html',
  },
  // Jordan 1
  {
    id: 6,
    cover: jordan7,
    types: [{ img: type6, text: 'IWRM' }],
    flags: [
      {
        id: 1,
        country: 'JO',
        image: jo,
      },
    ],
    map: map7,
    url: 'https://www.giz.de/en/worldwide/122919.html',
  },
  // Jordan 2
  {
    id: 7,
    cover: jordan8,
    types: [{ img: type6, text: 'IWRM' }],
    flags: [
      {
        id: 1,
        country: 'JO',
        image: jo,
      },
    ],
    map: map8,
    url: 'https://www.giz.de/en/worldwide/36859.html',
  },
  // Ethiopia
  {
    id: 8,
    cover: ethiopia,
    types: [{ img: type9, text: 'WEFE_nexus' }],
    flags: [
      {
        id: 1,
        country: 'ET',
        image: et,
      },
    ],
    map: map9,
    url: 'https://www.giz.de/en/worldwide/139466.html',
  },
  // Yemen
  {
    id: 9,
    cover: yemen,
    types: [
      { img: type7, text: 'water_peace' },
      { img: type8, text: 'climate_resiliance' },
    ],
    flags: [
      {
        id: 1,
        country: 'YE',
        image: ye,
      },
    ],
    map: map10,
    url: '',
  },
  // Mongolia
  {
    id: 10,
    cover: mongolia,
    types: [{ img: type2, text: 'ecosystem_restoration_protection' }],
    flags: [
      {
        id: 1,
        country: 'MN',
        image: mn,
      },
    ],
    map: map11,
    url: 'https://www.giz.de/en/worldwide/143856.html',
  },
];
