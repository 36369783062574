/* eslint-disable max-len */
import { t } from 'i18next';
import logo from 'assets/images/footer/logo.svg';
import React from 'react';
import { links } from './FooterLinks';
import { footerInformation } from 'utils/utilities';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const FooterSocial = () => (
  <div tw="bg-blue-800 text-blue-100 p-[2rem 4rem] sm:p-[1rem]">
    <div tw="max-w-[90rem] m-auto">
      <div tw="flex sm:flex-col gap-[4rem]">
        <div tw="w-[18rem]">
          <h2 tw="sm:text-xl sm:pb-[1rem] text-xxl font-semiBold sm:max-w-[16rem] xs:max-w-[7.6rem]">
            WATER CRISIS CLOCK
          </h2>
          <div tw="sm:pr-[0.5rem]">by</div>
          <img
            tw="inline-block pt-[0.5rem] pr-[0.5rem]"
            src={logo}
            alt="Footer"
          />
          <span tw="sm:inline-block sm:text-sm text-regular">
            WORLD DATA LAB
          </span>
        </div>
        <ul tw="flex flex-col gap-4">
          {footerInformation.map(({ id, name, url }: any) => (
            <li key={id} tw="text-blue-100 underline">
              <a href={url} target="_blank" rel="noopener noreferrer">{t(name)}</a>
            </li>
          ))}
        </ul>
        <div tw="block">
          <h3 tw="tracking-[0.2em] pb-[1rem]">CONTACT US</h3>
          <a href="mailto:wpc@worlddata.io" tw="pb-[1.5rem]">
            wpc@worlddata.io
          </a>
          <ul tw="flex mt-[1rem] gap-[1rem]">
            {links.map(({ name, image, url }) => (
              <li key={name}>
                <a
                  href={url}
                  key={name}
                  tw="bg-blue-100 max-w-[2.5rem] w-full p-[0.625rem] flex rounded-[50%]"
                >
                  <img src={image} alt={name} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default FooterSocial;
