/* eslint-disable max-len */
/* eslint-disable indent */
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import arrow from 'assets/images/arrow.svg';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import { Country } from 'interfaces/autocomplete-country.interface';
import { MobileFilters } from 'interfaces/mobile-filters.interface';
import React, { BaseSyntheticEvent } from 'react';
import { theme } from 'twin.macro';
import ClearIcon from './ClearIcon';
import { filterCountriesWithValidStatistics } from 'utils/utilities';

const CountryInput = ({
  isFromMobileFilters,
  handleToggleState,
}: MobileFilters) => {
  const { availableCountries, selectedCountry, setActiveCountry } =
    useWaterScarcity();

  const TextFieldStyle = {
    '& .MuiAutocomplete-clearIndicator': {
      marginRight: '2rem !important',
      visibility: 'visible !important',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'unset !important',
    },
    '& .MuiAutocomplete-input': {
      background: selectedCountry ? theme`colors.blue.100` : '',
      borderRadius: '0.5rem',
      color: theme`colors.blue.600`,
      fontWeight: selectedCountry ? 700 : 400,
    },
    '& .MuiAutocomplete-popupIndicator': {
      background: 'transparent',
    },
    '& .MuiAutocomplete-popupIndicator svg': {
      background: `url(${arrow}) no-repeat 100%`,
      backgroundPosition: 'right 0.5rem center',
      backgroundSize: '0.75rem',
      color: 'transparent',
    },
    '& .MuiAutocomplete-popupIndicatorOpen svg': {
      background: `url(${arrow}) no-repeat 100%`,
      backgroundPosition: 'right 0.3rem center',
      backgroundSize: '0.75rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiTextField-root': {
      backgroundColor: isFromMobileFilters
        ? theme`colors.white`
        : 'transparent',
      border: '1px solid #0050c0',
      borderRadius: '0.5rem',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0.06rem',
    },
  };

  const handleChange = ({ target }: BaseSyntheticEvent) => {
    handleToggleState &&
      !selectedCountry &&
      handleToggleState('areMobileFiltersActive');
    target.id
      ? setActiveCountry({
          id: target.id,
          name: target.name,
        })
      : setActiveCountry({
          id: null,
          name: null,
        });
  };

  return (
    <Autocomplete
      clearIcon={<ClearIcon />}
      sx={TextFieldStyle}
      onChange={handleChange}
      options={
        filterCountriesWithValidStatistics(availableCountries)
          ? filterCountriesWithValidStatistics(availableCountries)
              .filter(({ name }: { name: string }) => name)
              .sort((countryA: any, countryB: any) =>
                countryA.name.localeCompare(countryB.name),
              )
          : []
      }
      autoHighlight
      getOptionLabel={(option: Country) => option.name}
      value={selectedCountry ? { name: selectedCountry.name } : (null as any)}
      renderOption={(props: any, option: Country) => (
        <button
          {...props}
          id={option.iso3c}
          name={option.name}
          style={{
            color: theme`colors.blue.600`,
            display: 'flex',
            fontSize: '0.8rem',
            fontWeight: 700,
            gap: '0.65rem',
            width: '100%',
          }}
        >
          <img
            loading="lazy"
            width="16"
            src={option.file_url}
            alt={option.name}
          />
          {option.name}
        </button>
      )}
      renderInput={(params: any) => {
        const placeholder = t('search_placeholder') as string;

        return (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              placeholder,
            }}
          />
        );
      }}
    />
  );
};

export default CountryInput;
