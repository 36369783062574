import cases from 'assets/images/aboutPage/cases.svg';
import logo from 'assets/images/logo.svg';
import { useWaterScarcity } from 'context/waterScarcity';
import { t } from 'i18next';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';


export interface TitleLogoType {
  isAboutPage?: boolean;
}
const TitleLogo = ({ isAboutPage }: TitleLogoType) => {
  return (
    <>
      <a href="#hero-section" tw="flex items-center gap-[0.5rem]">
        <img
          css={[tw`sm:w-[2rem] md:hidden`, isAboutPage && tw`hidden`]}
          src={logo}
          alt="Water Crisis Clock Logo"
        />
        <img
          css={[tw`sm:w-[2rem] hidden md:block`, isAboutPage && tw`hidden`]}
          src={logo}
          alt="Water Crisis Clock Logo"
        />
        <div tw="mt-[0.5rem] text-xs sm:m-0">
          {isAboutPage ? (
            <div tw="flex items-center gap-[0.5rem]">
              <h2 css={[tw`text-blue-700 text-xl uppercase`]}>
                {t('title_about_page')}
              </h2>
            </div>
          ) : (
            <>
              <h1 tw="text-blue-600 uppercase">{t('title')}</h1>
              <span tw="text-blue-400">{t('sub_title')}</span>
            </>
          )}
        </div>
      </a>
      {isAboutPage && (
        <article tw="grid grid-cols-[repeat(3, 1fr)] items-center gap-[2rem 4rem] mt-[3rem] md:flex md:flex-col justify-center">
          <img alt="Cases" src={cases} />
          <p tw="md:text-sm text-[1.16rem] font-medium">
            <span tw=" font-bold">{t('about_page_5p_1r')}</span>
            {t('about_page_5p_2r')}
          </p>
        </article>
      )}
    </>
  );
};
export default TitleLogo;
